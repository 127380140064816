import React from 'react';
import './MagicEightBall.css';

const inputStyle = {
  width: 235,
  margin: 5
};

class MagicEightBall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      userInput: '',
      randomIndex: '',
      clicked: false,
      currentQuestion: ''
    };
    this.ask = this.ask.bind(this);
    this.handleChange = this.handleChange.bind(this);
  }

  ask() {
    if (this.state.userInput) {
      this.setState({
        randomIndex: Math.floor(Math.random() * 20),
        userInput: '',
        clicked: true,
        currentQuestion: this.state.userInput
      });
    }
  }

  handleChange(event) {
    this.setState({
      userInput: event.target.value,
      currentQuestion: this.state.userInput
    });
  }

  render() {
    const possibleAnswers = [
      'It is certain',
      'It is decidedly so',
      'Without a doubt',
      'Yes, definitely',
      'You may rely on it',
      'As I see it, yes',
      'Outlook good',
      'Yes',
      'Signs point to yes',
      'Reply hazy try again',
      'Ask again later',
      'Better not tell you now',
      'Cannot predict now',
      'Concentrate and ask again',
      "Don't count on it",
      'My reply is no',
      'My sources say no',
      'Most likely',
      'Outlook not so good',
      'Very doubtful'
    ];
    const answer = possibleAnswers[this.state.randomIndex];
    var className = this.state.clicked ? 'click-state' : 'base-state';
    let action;
    if (this.state.clicked) {
      action = <h2>{this.state.currentQuestion}</h2>
    } else {
      action = <input
        type='text'
        value={this.state.userInput}
        onChange={this.handleChange}
        style={inputStyle}
        placeholder='Type your question'
      />
    }

    return (
      <div className='magicEightBall'>
        {action}
        <br />
        <br />
        <section class="stage">
          <button className={'ball ' + className} onClick={this.ask}>
            <span class="shadow"></span>
            <div className='ballContent'>
              <span class="eight"></span>
              <span className={'answer ' + className}>{answer}</span>
            </div>
          </button>
        </section>
      </div>
    );
  }
}

export default MagicEightBall;